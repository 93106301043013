export default {
  methods: {
    Download: function (file, isEncrypt, hashed) {
      let url;

      if (isEncrypt) {
        url = `${import.meta.env.VITE_API_URL}/file/get?file_name=${file.file_name}&hashed=${hashed}&download=true`;
      } else {
        url = file["download_url"];
      }

      if (file.user_id !== this.$store.state.user.sub) {
        this.$axios.get(
          `/file/download-notification?file_name=${file.file_name}`,
        );
      }

      // flutter Javascript Channel
      if (window.flutterSendMessage) {
        window.flutterSendMessage.postMessage(
          JSON.stringify({
            type: "download",
            message: { fileName: file.file_name, url: url },
          }),
        );
      } else {
        window.open(url, "_blank").focus();
      }
    },
    /**
     * 文字列をダウンロード可能なファイルとしてユーザーに提供する関数
     * @param {string} content - ダウンロードする内容となる文字列
     * @param {string} fileName - ダウンロードするファイルの名前
     * @param {string} contentType - ファイルのコンテンツタイプ（例: 'text/plain'）
     */
    DownloadStringAsFile: function (content, fileName, contentType) {
      const blob = new Blob([content], { type: contentType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
