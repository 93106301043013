<template>
  <b-button v-if="!$store.state.isLoggedIn" class="has-text-weight-bold is-primary is-focused"
    style="padding-top: 30px;padding-bottom: 30px" @click="signUp(redirect_uri)" rounded><span
      class="is-size-7 has-text-white-ter">＼
      SNSでかんたん ／</span><br>アカウント作成
    <span class="has-text-white-ter">ー無料</span></b-button>
</template>

<script>
export default {
  name: "ButtonSignUp",
  props: {
    redirect_uri: {
      type: String,
      default: '/dashboard'
    }
  }
}
</script>

<style lang="scss" scoped></style>
