<template>
  <footer class="footer has-text-weight-bold">
    <div class="content">
      <div class="columns has-text-left">
        <div class="column">
          <p class="left-logo margin-bottom-30px">
            <img class="left-logo" src="/static/images/logo-64x64.avif" width="64" height="64"
              alt="HStorage クラウドストレージ ロゴ" loading="lazy">
            <span>HStorage.io
              <a href="https://twitter.com/hstorage_io" rel="noreferrer" aria-label="twitter プロフィール"
                style="margin-right:6px">
                <font-awesome-icon :icon='["fab", "twitter"]' />
              </a>
              <a href="https://forum.hstorage.io" aria-label="ユーザーコミュニティサイト" rel="noreferrer" target="_blank">
                <font-awesome-icon :icon='["fab", "discourse"]' />
              </a>
            </span>
          </p>
          <p>{{ $t('footer.description') }}</p>
          <ButtonSignUp size="is-small" />
          <div class="margin-top-18px">
            <AppLinks :isDark="true"></AppLinks>
          </div>
          <div class="copyright">
            <p><a href="https://hcloud.ltd" rel="noreferrer">合同会社 HCloud
                <font-awesome-icon icon="external-link-alt" />
              </a></p>
          </div>
        </div>
        <div class="column">
          <p>Resources</p>
          <div class="columns">
            <div class="column">
              <p>
                <a href="/#tokutyou">{{ $t('top.tokutyou.title') }}</a>
              </p>
              <p>
                <a href="/#features">{{ $t('top.features.title') }}</a>
              </p>
              <p id="footer-link-subscription">
                <router-link to="/subscription">{{ $t('subscription.title') }}</router-link>
              </p>
              <p>
                <router-link to="/faq">{{ $t('faq.title') }}</router-link>
              </p>
            </div>
            <div class="column">
              <p>
                <a href="/blog">公式ブログ</a>
              </p>
              <p>
                <a href="https://status.hcloud.ltd/status/hstorage">障害情報</a>
              </p>
            </div>
            <div class="column">
              <p>
                <a href="https://forum.hstorage.io" target="_blank" rel="noreferrer"
                  aria-label="ユーザーコミュニティサイトフォーラム">コミニュティ</a>
              </p>
              <p>
                <a href="/blog/about/2022/09/15/media-guide.html">広告掲載について</a>
              </p>
              <p>
                <a href="https://forum.hstorage.io/c/news/38" target="_blank" rel="noreferrer"
                  aria-label="最新情報">最新情報</a>
              </p>
              <p>
                <router-link to="/contact">{{ $t('contact.title') }}</router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="column">
          <p>Confidence</p>
          <p>
            <router-link to="/tos">{{ $t('tos.title') }}</router-link>
          </p>
          <p>
            <router-link to="/privacy">{{ $t('privacy.title') }}</router-link>
          </p>
          <p>
            <router-link to="/asct">{{ $t('asct.title') }}</router-link>
          </p>
          <p>
            <router-link to="it-security-basic-policy">{{ $t('it_security_basic_policy.title') }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ButtonSignUp from "./ButtonSignUp.vue";
import AppLinks from "components/AppLinks.vue";

export default {
  name: 'Footer',
  components: { AppLinks, ButtonSignUp }
}
</script>

<style lang="scss" scoped>
p.left-logo {
  margin-top: -18px;
  vertical-align: middle;
}

img.left-logo {
  margin-right: 12px;
  vertical-align: middle;
}

div.copyright {
  margin-top: 12px;
}

a {
  color: #fff;
}

.footer {
  margin-top: auto; // footer ページ下部固定
  margin-bottom: -3.5rem; // ダッシュボードボタン（padding-bottom 分）
  background-color: #4a4a4a;
  color: #ffffff;
}
</style>
