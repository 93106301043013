<template>
  <b-button id="login" data-id="login" @click="login(redirect_uri)" v-if="!$store.state.isLoggedIn"
    class="has-text-weight-bold" rounded>{{ $t('login') }}
  </b-button>
</template>

<script>
export default {
  name: "ButtonLogin",
  props: {
    redirect_uri: {
      type: String,
      default: '/dashboard'
    }
  },
}
</script>

<style lang="scss" scoped></style>
