import { createApp } from "vue";
import { createHead } from "@unhead/vue";
import { store } from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Buefy from "buefy";
import "buefy/dist/buefy.min.css";
import App from "./App.vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/vue";
import VueLazyload from "vue3-lazyload";
import MarqueeText from "vue-marquee-text-component";
import VueMobileDetection from "vue-mobile-detection";
import { createAuth0 } from "@auth0/auth0-vue";
import adsUtils from "./utils/ads";
import copyUtils from "./utils/copy";
import toastUtils from "./utils/toast";
import loadingUtils from "./utils/loading";
import auth0Utils from "./utils/auth0";
import utilsUtils from "./utils/utils";
import modalUtils from "./utils/modal";
import downloadUtils from "./utils/download";
import emailUtils from "./utils/email";
import shareUtils from "./utils/share";
import groupUtils from "./utils/group";
import errorUtils from "./utils/error";

import router from "./router";
import { createRouterScroller } from "vue-router-better-scroller";
import { i18n } from "./i18n-setup";

import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });

import { library } from "@fortawesome/fontawesome-svg-core";

// free solid
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons/faCloudArrowDown";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faListUl } from "@fortawesome/free-solid-svg-icons/faListUl";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons/faCloudArrowUp";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faInfinity } from "@fortawesome/free-solid-svg-icons/faInfinity";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";
import { faCodeMerge } from "@fortawesome/free-solid-svg-icons/faCodeMerge";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faSkullCrossbones } from "@fortawesome/free-solid-svg-icons/faSkullCrossbones";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle"; // UploadComponent number-input
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons/faEllipsisVertical";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faUpLong } from "@fortawesome/free-solid-svg-icons/faUpLong";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons/faShareNodes";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faShield } from "@fortawesome/free-solid-svg-icons/faShield";
import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons/faArrowDownWideShort";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons/faCalendarDays";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons/faMicrochip";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";

// free brand
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faDiscourse } from "@fortawesome/free-brands-svg-icons/faDiscourse";

library.add(
  faCloudArrowDown,
  faEye,
  faEyeSlash,
  faChevronDown,
  faClock,
  faShield,
  faLink,
  faRotateRight,
  faUpload,
  faFile,
  faEllipsisVertical,
  faMicrochip,
  faKey,
  faCalendarDays,
  faArrowDownWideShort,
  faExclamationCircle,
  faSkullCrossbones,
  faPaperPlane,
  faCheck,
  faDiscourse,
  faUpLong,
  faCodeMerge,
  faUserGroup,
  faInfinity,
  faMinus,
  faPlus,
  faCloudArrowUp,
  faSignInAlt,
  faChevronRight,
  faShareNodes,
  faRocket,
  faShieldAlt,
  faUserAlt,
  faEnvelope,
  faHandHoldingUsd,
  faExclamation,
  faAngleDown,
  faAngleUp,
  faUsers,
  faLayerGroup,
  faTimesCircle,
  faTools,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faUserPlus,
  faRedoAlt,
  faSignOutAlt,
  faListUl,
  faHome,
  faTwitter,
  faExternalLinkAlt,
  faQuestionCircle,
  faTrash,
);

const app = createApp(App);
const head = createHead();

app.mixin(adsUtils);
app.mixin(copyUtils);
app.mixin(toastUtils);
app.mixin(loadingUtils);
app.mixin(auth0Utils);
app.mixin(utilsUtils);
app.mixin(modalUtils);
app.mixin(downloadUtils);
app.mixin(emailUtils);
app.mixin(shareUtils);
app.mixin(groupUtils);
app.mixin(errorUtils);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("marquee-text", MarqueeText);

app.use(
  createAuth0({
    domain: "auth.hstorage.io",
    clientId: "gU0Zw3QFQFJlAY8kSiLRINc0yKiYvOpc",
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://easyuploader.auth0.com/api/v2/",
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  }),
);

app.use(Buefy, {
  defaultIconPack: "fas",
  defaultIconComponent: "font-awesome-icon",
});

app.use(VueMobileDetection);

app.use(VueLazyload, {
  error: "/static/images/loading.svg",
  loading: "/static/images/loading.svg",
});

import ScriptX from "vue-scriptx";

app.use(ScriptX);

import Ads from "vue-google-adsense";

app.use(Ads.Adsense);

app.use(i18n);

if (import.meta.env.VITE_STAGE === "production") {
  Sentry.init({
    app,
    dsn: "https://8c0ca47f78014a999c1705dc102d9ee5@o1339002.ingest.sentry.io/4505544934621184",
    ignoreErrors: [
      "Can't find variable: YAHOO",
      "Failed to fetch",
      "Illegal invocation",
      "IndexSizeError",
      "Load failed",
      "Network Error",
      "No error message",
      "NotAllowedError",
      "NotSupportedError",
      "Request failed with status code 400",
      "Request failed with status code 401",
      "ResizeObserver loop completed with undelivered notifications",
      "TagError",
      "The object is in an invalid state",
      "Unable to preload CSS", // ostr.io で発生する https://hcloud.sentry.io/issues/5238526842/?project=4505544934621184&query=is%3Aunresolved&referrer=issue-stream&stream_index=0
      "Unexpected end of input",
      "Unexpected token",
      "ethereum",
      "is not a valid JavaScript MIME type", // https://hcloud.sentry.io/issues/5083744418/?project=4505544934621184&referrer=webhooks_plugin
      "need-premium",
      "null is not an object (evaluating 'navbar.style')", // https://hcloud.sentry.io/issues/5250479518/?project=4505544934621184&query=is%3Aunresolved&referrer=issue-stream&stream_index=2
      "safari-web-extension://",
    ],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        workerUrl: "/static/sentry-replay-worker.min.js",
        networkDetailAllowUrls: [window.location.origin, "api.hstorage.io"],
        networkRequestHeaders: ["Authorization"],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.config.errorHandler = (err) => {
  store.state.isError = true;
  store.state.errorMsg = err;
};

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});
client.interceptors.response.use(
  (response) => {
    return response;
  },
  // 2xxの範囲外にある任意のステータスコードはこの関数をトリガーします
  // レスポンスエラーで何かを実行する
  (error) => {
    if (error.response) {
      if (error.response.status !== 400 && error.response.status !== 404) {
        Sentry.captureException(`axios: [error] ${error.message}`);
      }
    } else if (error.request) {
      // リクエストが行われましたが、レスポンスは受信されませんでした
      Sentry.captureException(`axios: [error] ${error.message}`);
    } else {
      // リクエストの設定中にエラーがトリガーされた何かがありました
      Sentry.captureException(`axios: [error] ${error.message}`);
    }
    return Promise.reject(error);
  },
);
axiosRetry(client, {
  retries: 5,
  retryDelay: function (retryCount, error) {
    return 1000;
  },
});
app.config.globalProperties.$axios = client;

app.use(router);
app.use(
  createRouterScroller({
    selectors: {
      window: true,
      body: true,
      ".scrollable": true,
    },
  }),
);
app.use(store);
app.use(head);
app.mount("#app");
