export default {
    methods: {
      IsMobile: function () {
        const userAgent = navigator.userAgent.toLowerCase();
        return /android|webos|iphone|ipad|ipod|blackberry|windows phone/.test(userAgent);
      },
        Sleep: function (msec) {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    resolve()
                }, msec)
            })
        },
        DeleteFile: function (fileName) {
            return new Promise((resolve, reject) => {
                this.openLoading()

                this.$axios.delete(`/file/my?file_name=${fileName}`, {headers: {'Authorization': `Bearer ${this.$store.state.token}`}})
                    .then(response => {
                        this.toastInfo(this.$t('delete.deleted'))
                        resolve()
                        window.location.reload()
                    }).catch(err => {
                    this.closeLoading()
                    this.$buefy.dialog.alert({
                        title: err.response.data.title,
                        message: err.response.data.msg,
                    })
                    reject(err)
                })
            })
        },
        FormatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        FormatBytesToGB(bytes) {
            return bytes / 1024 / 1024
        },
        CreateGroup: function (groupName, groupCount) {
            return new Promise((resolve, reject) => {
                if (!this.$store.state.isLoggedIn) {
                    this.$store.state.isUserRegistrationModal = true
                    reject(new Error('need-login'))
                    return
                }

                if (groupCount >= 1 && this.$store.state.isFree) {
                    this.OpenPremiumModal('グループの制限に達しました', '各プランを7日間無料でお試しできます 💁')
                    reject(new Error('need-premium'))
                    return
                }

                this.$axios.put(`/group`, {name: groupName}, {headers: {'Authorization': `Bearer ${this.$store.state.token}`}})
                    .then(response => {
                        this.$buefy.dialog.alert(this.$t('dashboard.group.created'))
                        resolve(response)
                    }).catch(err => {
                    this.$buefy.dialog.alert({
                        title: err.response.data.title,
                        message: err.response.data.msg,
                    })
                    reject()
                })
            })
        },
        GetSizeIsSmall: function () {
            if (this.$isMobile()) {
                return 'is-small'
            }
        }
    }
}
